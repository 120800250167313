import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/source'

class SourceService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getInvoices(connectionId, bodyData){
        return http.post(`${this.getUrl()}/${connectionId}/get-invoices`, bodyData)
    }
}

export default new SourceService()