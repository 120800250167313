<template>
    <Overview 
        ref="Overview" 
        :moduleName="'Factuur'"
        :moduleNamePlural="'Synchronisatie'"
        :sort="['date_sent', 'identifier']"
        :sortOrder="'-1'"
        :itemService="itemService"
        :itemFormRef="itemFormRef"
        :disableNewButton="true"
        :disableEdit="false"
    >

        <template #header v-if="isAdmin">
            <div class="mb-8 row">
                <div class="col-sm-4">
                    <select v-model="selectedSource" class="form-select form-select-xs" id="source_id" @change="extraFilter('source')"> 
                    <option v-for="source in sources" :key="source.id" :value="source.id" >
                        {{source.name}}
                    </option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <select v-model="selectedConnection" class="form-select form-select-xs" id="customer_id" @change="extraFilter">   
                        <option v-for="connection in filteredConnections" :key="connection.id" :value="connection.id" >
                            {{connection.name}}
                        </option>
                    </select>
                </div>
            </div>
            
           
        </template>
        
        <template #columns>
            <Column header="Klant" field="connection.customer.name" :sortable="true" v-if="isAdmin"></Column>
            <Column field="connection.name" header="Referentie" :sortable="true"></Column>
            <Column field="identifier" header="Factuur" :sortable="true" style="width: 10rem"></Column>
            <Column field="date_sent" header="Factuurdatum" :sortable="true">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.date_sent, 'shortDate')}}
                </template>
            </Column>
            <Column field="source_status" header="Bron status" :sortable="true" style="width: 7rem"></Column>
            <Column field="debtor.company_name" header="Debiteur" :sortable="true" style="width: 15rem"></Column>
            <Column field="total_excl_vat" header="Totaal (incl.)" :sortable="true">
                <template #body="slotProps">
                    <template  v-if="slotProps.data.total_excl_vat">
                        &euro; {{formatCurrency(parseFloat(slotProps.data.total_excl_vat) + parseFloat(slotProps.data.total_vat))}}
                    </template>
                </template>
            </Column>
            <Column field="total_excl_vat" header="Totaal btw" :sortable="true" >
                <template #body="slotProps">
                    <template  v-if="slotProps.data.total_vat">
                        &euro; {{formatCurrency(slotProps.data.total_vat)}}
                    </template>
                </template>
            </Column>
            
           
            <Column field="status" header="Status" :sortable="true" style="width: 10rem">
                <template #body="slotProps">
                    <p>
                        
                        <template v-if="slotProps.data.status === 'Geïmporteerd'">
                            &#129000;
                        </template>
                        <template v-if="slotProps.data.status === 'Geëxporteerd'">
                            &#129001;
                        </template>
                        <template v-if="slotProps.data.status === 'Fout'">
                            &#128997;
                        </template>
                        <template v-if="slotProps.data.last_error">(!)</template>
                        {{slotProps.data.status}}
                    </p>
                </template>
            </Column>
        </template>

        <template #actions>
            <Column :exportable="false" style="width: 10rem">
                <template #body="slotProps">
                    <div class="text-end">
                        <a v-if="isAdmin" class="fa-stack" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, false)">
                            <i class="fas fa-circle fa-stack-2x text-info"></i>
                            <i class="fal fa-bug fa-stack-1x fa-magnifying-glass"></i>
                        </a>
                    
                        <a v-if="loading[slotProps.data.id]" class="fa-stack">
                            <i class="fas fa-circle fa-stack-2x text-info"></i>
                            <i class="fal fa-spin fa-spinner fa-stack-1x fa-inverse"></i>
                        </a>
                        <a v-else class="fa-stack" @click="exportInvoice(slotProps.data.connection_id, slotProps.data.connection.destination_connection.id, slotProps.data)">
                            <i class="fas fa-circle fa-stack-2x text-warning"></i>
                            <i class="fal fa-file-export fa-stack-1x fa-inverse"></i>
                        </a>
                  
                        <a class="fa-stack" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true)" v-if="slotProps.data.last_error">
                            <i class="fas fa-circle fa-stack-2x text-danger"></i>
                            <i class="fal fa-bug fa-stack-1x fa-inverse"></i>
                        </a>
                        <a v-if="isAdmin" class="fa-stack" data-bs-toggle="modal"  @click="delItem(slotProps.data, true)" >
                            <i class="fas fa-circle fa-stack-2x text-danger"></i>
                            <i class="fal fa-trash-alt fa-stack-1x fa-inverse"></i>
                        </a>
                    </div>
                </template>
            </Column>
        </template>
            

        
        <template #form>
            <invoiceFormComponent ref="ItemForm" :itemData="item" :isAdmin="isAdmin" :debug="debug"/>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import invoiceFormComponent from '@/components/invoice/InvoiceFormComponent';
import invoiceService from '@/services/InvoiceService';
import connectionService from '@/services/ConnectionService';
import sourceService from '@/services/SourceService';

import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        invoiceFormComponent
    },
    data() {
        return {
            item: {},
            itemService: invoiceService,
            itemFormRef: null,
            loading: [],
            debug: true,
            connections: [],
            sources: [],
            selectedConnection: null,
            selectedSource: null,
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }, 
        
        filteredConnections(){
            if(this.selectedSource !== null){
                return  this.connections.filter((connection) => connection.source_connection.source_id == this.selectedSource);
             }else{
                
                return this.connections;
             }
          
        }
    },
    methods: {
        exportInvoice(connectionId, destinationId, invoice){
            if(invoice.status != 'Geëxporteerd' || confirm('Export is al Geëxporteerd, nogmaals exporteren?')){
                this.loading[invoice.id] = true
                invoiceService.exportInvoices(connectionId, destinationId, [invoice.id]).catch(error => {
                    console.log('error', error)
                    this.toastError(error)
                }).finally(() => {
                    this.loading[invoice.id] = false  
                    this.$refs.Overview.getIndex()
                })
            }
        },
        editItem(item, debug = true) {
            this.debug = debug;
            this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(this.item);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
        delItem(item){
            confirm('Weet je zeker dat je deze factuur '+item.identifier+' wilt verwijderen?') &&
            this.itemService.delete(item.id).then(response => {
                console.log(response);
                this.$refs.Overview.getIndex();
                document.querySelector("#formModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },

        extraFilter(type) {
            if(type=='source'){
                this.selectedConnection = null;
            }
            this.$refs.Overview.extraFilter = {connectionFilter: this.selectedConnection, sourceFilter: this.selectedSource}
            this.$refs.Overview.getIndex();
            
            
        },
        getConnections() {
            connectionService.index().then(response => {
                this.connections = response.data
            }).catch(error => {
                console.log('error', error)
            })    
        },
        getSources() {
            sourceService.index().then(response => {
                this.sources = response.data
            }).catch(error => {
                console.log('error', error)
            })    
        },
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(this.isAdmin){
            this.getConnections(),
            this.getSources()
        }
    }
}
</script>

<style scoped>

    a.fa-stack {
        cursor: pointer;
    }

</style>