<template>
    <div v-if="invoice.last_error && debug">
        <pre>{{invoice.last_error}}</pre>
    </div>
    <div v-if="isAdmin && !debug">
        <h1>Debtor</h1>
        <pre>{{invoice.debtor}}</pre>
        <h1>Lines</h1>
        <pre>{{invoice.invoice_lines}}</pre>
    </div>
</template>

<script>
    import invoiceservice from '@/services/InvoiceService'
    import { Modal } from 'bootstrap'

    export default {
        name: 'InvoiceFormComponent',
        props: ['itemId', 'isAdmin', 'debug'],
        data() {
            return {
                invoice: {
                    'last_error': null,
                    
                },
                customertypes: [],
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
         watch: {
            itemId: function (val) {
                if(val)
                    this.get();
            },
        },
       methods: {
            getData() {
                return this.invoice;
            },
            setData(data) {
                this.invoice = data
            },
            reset() {
                this.invoice = {},
                this.loading = false
                this.invalid = {}
            }

        },
        mounted () {
            this.reset()
        }
    }
</script>
