import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/invoice'

class InvoiceService extends ApiService {
    constructor(){
        super()
    }
    index(page, params, extraFilter) {
      var queryUrl = ''
      if (params && page) {
        const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        queryUrl = `?page=` + page + `&` + queryParams
      }
      console.log(extraFilter);
      if (extraFilter.connectionFilter) {
        queryUrl += `&connectionFilter=` + extraFilter.connectionFilter
      }
      if (extraFilter.sourceFilter) {
        queryUrl += `&sourceFilter=` + extraFilter.sourceFilter
      }
        return http.get(`${this.getUrl()}` + queryUrl)
    }
    getUrl(){
        return URL
    }
    exportInvoices(connectionId, destinationId, invoices){
        return http.post(`${this.getUrl()}/export`, {
            "connection_id": connectionId,
            "destination_id": destinationId,
            "invoices": invoices
        })
    }
}

export default new InvoiceService()